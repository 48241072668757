<template>
    <div class="add-modify-sentry">
        <el-dialog 
            title="选择地址"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="816px">
           <el-form :model="positionInfo" :rules="rules" ref="positionInfo" label-width="0" class="data-form" @submit.native.prevent>
               <el-form-item prop="sentryAddress">
                    <div class="map-contains">
                        <div class="address-search">
                            <el-input v-model="positionInfo.sentryAddress" class="input-w290-h32" id="addressSentrySearch" type="text" placeholder="请输入地址" clearable></el-input>
                        </div>
                        <div class="map" id="map"></div>
                        <map-bottom  @zoomOut="zoomOut" @zoomIn="zoomIn" @changeMapType="changeMapType"></map-bottom> 
                    </div>
                </el-form-item>
                <el-form-item prop="position">
                    <!-- <el-input class="input-w290-h32" v-model="positionInfo.position" placeholder="点击地图确定地址"></el-input> -->
                     <div class="position-address">
                        <p :class="{'empty':!positionInfo.position}">{{positionInfo.position ? positionInfo.position : '点击地图确定地址'}}</p>
                    </div>
                </el-form-item>
           </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData('positionInfo')"  value="确定"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import BaiduMap from '@/components/map/baiduMap';//百度地图
import positionMarker from '@/assets/location.png'
import mapBottom from '../../components/map/mapBottom';
export default {
    model: {
        prop: 'positionDialogVisible',
        event: 'positionEvent'
    },
    components:{mapBottom},
    props:{
        positionDialogVisible:{
            type: Boolean,
            default: true
        },
        positionInfo:{
            type: Object,
            default:() => {
                return {
                    position:'',
                    coordinate:'',
                    sentryAddress:''
                }
            }
        },
    },
    data(){
        var addressValidate = (rule, value, callback) => {
            if (value == '请点击按钮进行选择' || !value) {
                callback(new Error('请选择岗亭地址'));
            }else{
                callback();
            }
        };
        return {
            show: this.positionDialogVisible,
            rules:{
                position:[{ required: true, validator: addressValidate}],
            },
            map:null,
            center: {//中心点
                lng: 116.3975, 
                lat: 39.9085
            },
            marker:null,
            tmpPoint:'',
        }
    },
    mounted(){
        this.map = new BaiduMap();
        let that = this;
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('positionEvent',newVal);
                if(!newVal) {
                    this.$refs.positionInfo.resetFields();
                } else {
                    let that = this;
                    setTimeout(() => {
                        that.map.initMap('map', this.center, 15);
                        that.map.addSearchInput('addressSentrySearch',that.getPointAndAddress);
                        that.map.clearOverlays();
                        let backPoint = null;
                        if(that.positionInfo.coordinate) {
                            backPoint = JSON.parse(that.positionInfo.coordinate);
                            that.marker = that.map.createMarker(backPoint,{icon:{url:positionMarker,size:[40,50],anchor:[0,0]}},null,false,null,null,null,true,true)
                        } else {
                            backPoint = that.center
                        }
                        that.map.map.centerAndZoom(new BMap.Point(backPoint.lng,backPoint.lat), 14);
                        that.map.map.addEventListener('click',
                            that.getPointAndAddress
                        )
                        }
                    ,200);
                }
            },
            deep: true,
        },
        positionDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
        'positionInfo.position':{
            handler(newVal) {
                if(this.show && this.$refs.positionInfo) {
                    this.$nextTick( () => {
                        this.$refs.positionInfo.validateField(['position']);
                    })
                }
            },
            deep:true,
        }
    },
    methods:{
        /** 切换地图类型
         *@param {String} val 地图类型 
         */
        changeMapType(val) {
            this.map.setMapType(val);
        },
        /** 放大地图
         * 
         */
        zoomOut() {
            this.map.zoomOut();
        },
        /** 缩小地图
         * 
         */
        zoomIn() {
            this.map.zoomIn();
        },
        //提交数据
        submitData(formName){
            this.positionInfo.coordinate = JSON.stringify(this.tmpPoint);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('getPosition',this.positionInfo);
                }
            });
        },
        /**
         * 获取点和地址
         */
        getPointAndAddress(e){
            let that = this;
            var geocoder= new BMap.Geocoder();
            if(e.hasOwnProperty('search') && e.search) {
                that.positionInfo.position = e.address;
                that.tmpPoint = JSON.parse(JSON.stringify(e.point));
                console.log(e.point,'search');
            } else {
                that.map.clearOverlays();
                that.positionInfo.sentryAddress = '';
                //给地图添加点击事件
                geocoder.getLocation(e.point,(rs) => {
                    console.log(rs.point,'point');
                    that.marker = that.map.createMarker(rs.point,{icon:{url:positionMarker,size:[40,50],anchor:[0,0]}},null,false,null,null,null,true,true)
                    that.positionInfo.position =  rs.address;
                    that.tmpPoint = rs.point;
                });
            }
            
        },
    },
}
</script>
<style lang="scss" scoped>
    .position-address {
        width:100%; 
        min-height:32px; 
        border: 1px solid #DCDFE6;
        border-radius:4px;
        line-height: 20px;
        box-sizing:border-box; 
        padding:5px 12px 5px 15px;
        font-size:14px;
        display:flex;
        justify-content:space-between;
        .empty {
            color:#BFBFBF; 
        }
    }
    .is-error {
        .position-address {
            border-color:#F56C6C !important;
        }
    }
    .map-contains {
        position: relative;
        height: 360px;
        border-radius: 4px;
        margin-bottom: 24px;
        border: 1px solid #D9D9D9;
        .map{
            width: 100%;
            height: 100%;
        }
        .address-search {
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 9999;
        }
    }
</style>
