<template>
     <div class="config-pop">
        <el-dialog
            :title="comTitle"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="424px">
            <el-form :model="formInfo" ref="formInfo" :rules="rules" label-width="110px" class="data-form" @submit.native.prevent>
                <el-form-item label="平台" prop="platform">
                    <el-select class="inputw-w250-h32 select-customize-input" v-model="formInfo.platform" @change="changeSelect" popper-class="select-customize">
                        <el-option :value="1" label="微信公众号">微信公众号</el-option>
                        <el-option :value="2" label="微信小程序">微信小程序</el-option>
                    </el-select>
                </el-form-item>
                <div v-if="formInfo.platform == '1'">
                    <el-form-item label="开发者ID" prop="appId">
                        <el-input class="inputw-w250-h32" v-model="formInfo.appId" maxlength="200" placeholder="请输入开发者ID"></el-input>
                    </el-form-item>
                    <el-form-item label="开发者密码" prop="secret">
                        <el-input class="inputw-w250-h32" v-model="formInfo.secret" maxlength="200" placeholder="请输入开发者密码"></el-input>
                    </el-form-item>
                    <el-form-item label="微信公众号BIZ" prop="appuinBase64">
                        <el-input class="inputw-w250-h32" v-model="formInfo.appuinBase64" maxlength="200" placeholder="请输入微信公众号BIZ"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData('formInfo')"  value="确定"></tem-btn>
            </div>
        </el-dialog>
     </div>
</template>
<script>
    export default {
        model: {
            prop: 'configDialogVisible',
            event: 'configEvent'
        },
        props: {
            configDialogVisible: {
                type: Boolean,
                default: false,
            },
            configFormData: {
                type: Object,
                default: () => {
                    return {
                        appId: '',//开发者Id
                        secret: '',//开发者密码
                        appuinBase64: '',//微信公众号
                        orgId: '',
                        platform: 1
                    }
                }
            }
        },
        data() {
            return {
                comTitle: '配置',
                show: this.configDialogVisible,
                formInfo:{
                    secret: '',
                    appId: '',
                    appuinBase64: '',
                    orgId: '',
                    platform: ''
                },
                rules: {
                    platform: [
                        {required: true, message: '请选择平台', trigger: 'change'} 
                    ],
                    appId: [
                         {max: 32,message: '请输入长度为32位以内的开发者ID', trigger: 'change'} 
                    ],
                    secret: [
                         {max: 32,message: '请输入长度为32位以内的开发者密码', trigger: 'change'} 
                    ],
                    appuinBase64: [
                         {max: 32,  message: '请输入长度为32位以内的微信公众号BIZ', trigger: 'change'} 
                    ],
                }
            };
        },
        methods: {
            /** 提交表单
             * @param {String} formName 关键字
             */
            submitData(formName) {
                if (this.formInfo.platform == 2) {
                    this.formInfo.appId = '';
                    this.formInfo.secret = '';
                    this.formInfo.appuinBase64 = '';
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$emit('submitData',this.formInfo);
                        // this.formInfo.platform = parseInt(this.formInfo.platform);
                        
                    }
                });
            },
            /** 更改选项
             * @param {String} val
             */
            changeSelect(val) {

            }
        },
        watch: {
            //对父组件传来的地址进行监听
            show:{
                handler(newVal, oldVal) {
                    this.$emit('configEvent',newVal);
                    if(!newVal) {
                        this.$nextTick(() => {
                            this.$refs.formInfo.resetFields();
                        })
                    } else {
                        this.$nextTick(() => {
                            this.$refs.formInfo.clearValidate();
                        })
                    }
                },
                deep: true,
            },
            configDialogVisible:{
                handler(newVal, oldVal) {
                    this.show = newVal;
                },
                deep: true,
            },
            configFormData: {
                handler(val) {
                    this.formInfo = val;
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss">
    .config-pop .el-icon-circle-close {
        width: 14px;
    }
</style>