<template>
    <div class="">
        <el-dialog
            :title="comTitle"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="424px">
            <el-form :model="organizationInfo" :rules="rules" ref="organizationInfo" label-width="68px" class="data-form" @submit.native.prevent>
                <!-- <el-form-item label="上级机构" prop="parentOrgName" class="el-w292">
                    <popover @nodeClick="nodeClick" v-model="openOrClose" ref="tree" :data="newTreeData" v-show="showTree"  @hide="openOrClose = false" @show="openOrClose = true">
                        <div slot="treeReference" >
                            <div class="org-input">
                                <el-input placeholder="请选择组织机构" class="input-w290-h32" disabled :class="openOrClose ? 'pop-open-style' : 'pop-close-style'" v-model="organizationInfo.parentOrgName"></el-input>
                                <svg-icon :class="{'svg-icon': true, 'icon-link': true, 'icon-transform': !openOrClose} " icon-class="icon-select" size="16"></svg-icon>
                            </div>
                        </div>
                    </popover>
                </el-form-item> -->
                <el-form-item label="机构名称" prop="orgName">
                    <el-input class="input-w290-h32" v-model="organizationInfo.orgName" maxlength="20" placeholder="请输入机构名称"></el-input>
                </el-form-item>
                <el-form-item label="唯一码" prop="context">
                    <el-input class="input-w290-h32" v-model="organizationInfo.context" maxlength="20" placeholder="请输入唯一码" :disabled="title == 'add' ? false:true"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="address" >
                    <!-- <el-input class="input-w290-h32" placeholder="请输入岗亭地址" v-model="organizationInfo.address"  autocomplete="off"></el-input> -->
                    <!-- <el-input type="textarea" placeholder="请输入岗亭地址" v-model="organizationInfo.address" maxlength="100" :rows="1"></el-input> -->
                    <div class="address" style="" @click="seePosition">
                        <p :class="{'empty':!organizationInfo.address}">{{organizationInfo.address ? organizationInfo.address : '请点击按钮进行选择'}}</p>
                        <p class="position-icon">
                            <svg-icon class="icon" icon-class="icon_sentry_position" size="16"></svg-icon>
                        </p>
                    </div>
                </el-form-item>
                <el-form-item label="行业" prop="type">
                    <el-select v-model="organizationInfo.type"  placeholder="请选择">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="desc">
                    <el-input type="textarea" v-model="organizationInfo.desc" maxlength="100" :rows="7" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData('organizationInfo')"  value="确定"></tem-btn>
            </div>
        </el-dialog>
        <seePosition v-model="positionVisible" @getPosition="getPosition" :positionInfo="positionInfo"/>
    </div>
</template>
<script>
import popover  from '@/components/popover/index'
import seePosition from './seePosition'
export default {
    model: {
        prop: 'organizationDialogVisible',
        event: 'organizationEvent'
    },
    components:{
        popover,
        seePosition
    },
    props:{
        organizationDialogVisible:{
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default:'add'
        },
        organizationInfo:{
            type: Object,
            default:() => {
                return {
                    orgName:'',
                    context:'',
                    desc:'',
                    orgPid:'',
                    orgPname:'',
                    address:'',
                    areaCode:'50000',
                    type:''
                }
            }
        },
        treeData:{
            type: Array,
            default:() => {
                return []
            }
        },
    },
    computed:{
        comTitle(){
            return this.title == 'add' ? '新增机构' : '编辑机构'
        },
        showTree: function() {
            this.isStatistics = true;
            return  this.isStatistics;
        }
    },
    data(){
        return {
            options: [{
                value: 1,
                label: '汽车清洗'
            }, {
                value: 2,
                label: '汽车美容'
            }, {
                value: 3,
                label: '汽车维修'
            }, {
                value: 4,
                label: '加油站'
            },{
                value: 5,
                label: '教育培训'
            }, {
                value: 6,
                label: '家具装饰'
            }, {
                value: 7,
                label: '商铺'
            }, {
                value: 8,
                label: '火锅店'
            }, {
                value: 9,
                label: '其他'
            }],
            show: this.organizationDialogVisible,
            rules:{
                parentOrgName: [{ required: true, message: '请选择上级机构',trigger: 'change'}],
                desc: [{ min: 0, max: 100, message: '备注100个字符以内', trigger: 'change' }],
                orgName:[{ required: true, message: '请输入机构名称', trigger: 'change' },
                    { required: true, pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/, message: '中文或数字或字母，长度 1-20 个字符', trigger: 'change' },
                    ],
                context:[{ required: true, message: '请输入唯一码', trigger: 'change' },
                    { required: true, pattern: /^[A-Z]{4}$/, message: '大写字母，长度4个字符', trigger: 'change' },
                    ]
            },
            defaultProps:{
                children: 'children',
                label: 'permisName'
            },
            openOrClose: false,
            newTreeData:this.treeData,
            positionVisible:false,
            positionInfo:{
                position:'',
                coordinate:'',
                sentryAddress:''
            },
        }
    },
    watch: {
        show:{
            handler(newVal, oldVal) {
                this.$emit('organizationEvent',newVal);
                if(!newVal && this.$refs.organizationInfo) {
                    setTimeout(()=>{
                        this.$refs.organizationInfo.resetFields();
                    },300)
                    
                    //关闭弹框的时候  关闭popover组织机构
                    this.openOrClose = false;
                }
            },
            deep: true,
        },
        treeData:{
            handler(newVal, oldVal) {
                this.newTreeData = newVal;
            },
            deep: true,
        },
        organizationDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
    methods:{
        /**
         * 查看定位方法
         */
        seePosition(){
            if(this.title == 'add') {
                //清空定位信息  解决编辑和新增冲突问题
                this.organizationInfo.coordinate = '';
            }
            this.positionVisible = true;
            this.positionInfo.position = this.organizationInfo.address;
            this.positionInfo.coordinate = this.organizationInfo.coordinate;
        },
         /**
         * 地图弹窗里面传来的数据
         */
        getPosition(address){
            this.positionVisible = false;
            this.organizationInfo.address = address.position;
            this.organizationInfo.coordinate = address.coordinate;
        },
        submitData(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('submitData',this.organizationInfo);
                }
            });
        },
        nodeClick(obj,flag){
            this.$set( this.organizationInfo,'parentOrgName',obj.orgName);
            this.$set( this.organizationInfo,'orgPid',obj.orgId);
            //双击关闭popover
            if(flag == 'db') {
                this.openOrClose = false;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
// .add-modify-sentry { 
    .address {
        width:292px; 
        min-height:32px; 
        border: 1px solid #DCDFE6;
        border-radius:4px;
        line-height: 20px;
        box-sizing:border-box; 
        padding:5px 12px 5px 15px;
        font-size:14px;
        display:flex;
        justify-content:space-between;
        cursor: pointer;
    }
    .empty {
        color:#BFBFBF; 
    }
    .position-icon {
        height: 16px;
        cursor: pointer;
    }
    .is-error {
        .address {
            border-color:#F56C6C !important;
        }
    }
// }
</style>
