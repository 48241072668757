<template>
  <div>
    <div class="organization-management">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery"  @submit.native.prevent>
                    <div class="form jcl">
                        <div class="search-conditions">   
                            <el-form-item label="机构名称" prop="orgNameLike">
                                <el-input class="input-w290-h32" :maxlength="120"  v-model="listQuery.orgNameLike" placeholder="请输入机构名称"></el-input>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="includeSub">
                                <!-- <el-checkbox v-model="listQuery.includeSub">包含下级</el-checkbox> -->
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getTableList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="restForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize" :sortIndex.sync="listQuery.sort"
            :orderBy.sync="listQuery.orderField" @pagination="getTableList"></Table>
        </div>
    </div>
    <div class="copyright">Copyright©2018-{{(new Date()).getFullYear()}} 重庆几米信息技术有限公司 版权所有</div>
    <addOrModifyOrganization :title="title" @submitData="submitData" :treeData="treeData" :organizationInfo="organizationInfo" v-model="organizationDialogVisible"/>
    <config-pop  @submitData="submitConfigData" :configFormData="configFormData" v-model="configDialogVisible"/>
  </div>
</template>

<script>
  import Table from '@/components/table/table';
  import addOrModifyOrganization from './addOrModifyOrganization'
  import popover  from '@/components/popover/index';
  import configPop from './configPop';
  import {addOrgConfig,getOrgConfig} from '@/api/advertisingManagement';
  import {getToken,getOrgId,getOrgName} from '@/utils/cookie';
  import {
    listPageOrg,
    addOrg,
    modifyOrg,
    getOneOrgDetail,
    listTreeOrg

  } from '@/api/organization-management/index';
  export default {
    components: {
      Table,
      addOrModifyOrganization,
      popover,
      configPop
    },
    data() {
      return {
        configDialogVisible: false,//是否显示配置项
        configFormData: {
            appId: '',//开发者Id
            secret: '',//开发者密码
            appuinBase64: '',//微信公众号
            orgId: '',//orgId
            platform:1
        },
        organizationDialogVisible: false,//组织机构id
        //搜索栏数据
        listQuery:{
            orgNameLike:'',//机构名称
            total:10000,//数据条数
            includeSub:true,//是否包含下级
            pageIndex: 1,//当前查询页面
            pageSize: 10,//每页数据数量
            orderField:null,
            sort:''
        },
        organizationInfo: {
            orgName:'',
            context:'',
            desc:'',
            parentOrgName:'',
            orgPid:'',
            areaCode:'50000',
            type: ''
        },
        title:'add',
        treeData:[],//组织机构数据
        //表格数据
        table: {
          configItem: true, //是否开启表格项配置
          data: [],
          //表头按钮
          operateBtns:[
              {
                  value:'新增机构',
                  type:'primary',
                  event: () => {
                      //重置表单信息
                      this.listTreeOrg().then( res => {
                          this.title = 'add';
                          this.organizationDialogVisible = true;
                      });
                  }
              }
          ],
          header: [
            {
              type: 'index',
              label: '序号',
              width: '80',
              ifShow: true,
            },
            {
              prop: 'orgName',
              label: '机构名称',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'context',
              label: '唯一码',
              minWidth: '120',
              tooltip: true,
              ifShow: true,
            },
            {
                prop: 'type',
                label: '行业',
                minWidth: '120',
                tooltip: true,
                ifShow: true,
                render:(h,param) => {
                    var str = '';
                    switch (param.type) {  // 不同类型用不同图标
                        case 1:
                            str = '汽车清洗';
                            break;
                        case 2:
                            str = '汽车美容';
                            break;
                        case 3:
                            str = '汽车维修';
                            break;
                        case 4:
                            str = '加油站';
                            break;
                        case 5:
                            str = '教育培训';
                            break;
                        case 6:
                            str = '家具装饰';
                            break;
                        case 7:
                            str = '商铺';
                            break;
                        case 8:
                            str = '火锅店';
                            break;
                        case 9:
                            str = '其他';
                            break;
                        default:
                            str = '--';
                            break;
                    }
                    return h('div', [
                            h('span',{
                                
                            },str)
                    ]);
                }
            },
            {
              prop: 'address',
              label: '地址',
              minWidth: '250',
              tooltip: true,
              ifShow: true,
            },
            // {
            //   prop: 'coordinate',
            //   label: '坐标',
            //   minWidth: '250',
            //   tooltip: true,
            //   ifShow: false,
            // },
            {
              prop: 'desc',
              label: '备注',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'createTime',
              label: '创建时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'updateTime',
              label: '更新时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'options',
              label: '操作',
              minWidth: '205',
              ifShow: true,
              fixed: 'right',
              render:(h,param) => {
                return h('div', [
                        h('span',{
                            style:{
                                cursor:'pointer',
                                color:'#3270ff',
                                marginRight:'15px'
                            },
                            on:{
                                click:()=> {
                                    this.listTreeOrg(param.orgId).then( res => {
                                        
                                        this.title = 'edit';
                                        this.getOneOrgDetail(param.orgId);    
                                    });
                                }
                            }
                        },'编辑'),
                        h('span',{
                            style:{
                                cursor:'pointer',
                                color:'#3270ff',
                                marginRight:'15px'
                            },
                            on:{
                                click:()=> {
                                    this.listTreeOrg(param.orgId).then( res => {
                                        this.$router.push({
                                            name:'advertisingManagement',
                                            params:{
                                                orgName:param.orgName,
                                                orgId:param.orgId
                                            }
                                        })
                                    });
                                }
                            }
                        },'广告位管理'),
                        h('span',{
                            style:{
                                cursor:'pointer',
                                color:'#3270ff'
                            },
                            on:{
                                click:()=> {
                                    this.getConfigData(param.orgId).then(res => {
                                        
                                    })
                                    
                                    // this.configFun();
                                }
                            }
                        },'配置'),
                ]);
              }
            }
          ],
        }
      }
    },
    mounted() {
        //获取列表数据
        this.getTableList();
    },
    methods: {
        /**
         * 获取机构数据
         * @param {Number} orgId 机构id
         */
        listTreeOrg(orgId){
            return new Promise( ( resolve , reject ) => {
                listTreeOrg({currentOrgId:orgId,excludeChild:true}).then( res => {
                    if( res.code == 200 ) {
                        this.treeData = res.data;
                        resolve('success')
                    }
                })
            }) 
        },
        /**
         * 获取机构信息
         * @param {number} 机构id
         */
        getOneOrgDetail(orgId){
            var _ = this;
            getOneOrgDetail({orgId}).then( res => {
                if( res.code == 200 ) {
                    this.organizationDialogVisible = true;
                    this.$nextTick(() => {
                        this.organizationInfo = res.data;
                        this.organizationInfo.type = res.data.type == 0?'':res.data.type;
                    })
                }
            })
        },
        /**
         * 提交数据
         */
        submitData(organizationInfo) {
            if( this.title == 'add') {
                addOrg(organizationInfo).then( res => {
                    if( res.code === 200 ) {
                        this.getTableList();
                        this.organizationDialogVisible = false;
                        this.$message({type:'success',message:'新增机构成功'});
                    }else{
                        this.$message({type:'warning',message:res.data.message});
                    }
                })
            } else if( this.title == 'edit' ) {
                modifyOrg(organizationInfo).then( res => {
                    if( res.code === 200 ) {
                        this.getTableList();
                        this.organizationDialogVisible = false;
                        this.$message({type:'success',message:'编辑机构成功'});
                    }
                })
            }
        },
        /**
         * 重置搜索导航栏
         * @param { Object } formName 当前表单的ref值
         */
        restForm(formName){
            this.$refs[formName].resetFields();
        },
        /**
         * 获取表格数据
         */
        getTableList(flag){
            if( flag == 'search' ) {
                this.listQuery.pageIndex = 1;
            }
            listPageOrg(this.listQuery).then( res => {
                if( res.code === 200 ) {
                    this.table.data = res.data;
                    this.listQuery.total = res.count;
                }
            })
        },
        /** 获取已配置的值
         * @param {Number} orgId orgId
         */
        getConfigData(orgId) {
            var obj = {
                orgId: orgId || getOrgId(),
            } 
            let promise = new Promise((resolve, reject) => {
                getOrgConfig(obj).then(res => {
                    this.configDialogVisible = true;
                    if (res && res.data) {
                        // this.$nextTick(() => {
                            this.configFormData = {
                                appId: res.data.appId || '',//开发者Id
                                secret: res.data.secret || '',//开发者密码
                                appuinBase64: res.data.appuinBase64 || '',//微信公众号
                                orgId: obj.orgId || getOrgId(),
                                platform: res.data.platform
                            };
                        // })
                            
                    } else {
                            this.configFormData = {
                                appId: '',//开发者Id
                                secret: '',//开发者密码
                                appuinBase64: '',//微信公众号
                                orgId: obj.orgId || getOrgId(),
                                platform: ''
                            };
                    }
                    resolve('')
                })
            });
           return promise;
        },
        /** 弹出配置弹出
         * 
         */
        configFun() {

        },
        /** 提交配置 
         * @param {Object} obj 参数
        */
        submitConfigData(obj) {
            addOrgConfig(obj).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.configDialogVisible = false;
                    this.$message.success('配置成功')
                }
            })
        }
    }
  }

</script>
<style lang="scss" scoped>
.organization-management {
    
}


</style>